import React from "react";

// SITE INTRO
import Landing from "pages/siteIntro/landing"
import AboutUs from "pages/siteIntro/aboutUs"
import EventHome from "pages/siteIntro/eventHome"
import HostWithUs from "pages/siteIntro/hostWithUs"
import Datrimony from "pages/siteIntro/datrimony"
import Blog from "pages/siteIntro/blog"
import BlogDetail from "pages/siteIntro/blogDetail"

//  Auth
import LoginPage from 'pages/auth/Login'
import SignUpPage from 'pages/auth/signup';
import WelcomePage from 'pages/auth/welcome';

// On Boarding
import OnBoardingFirstPage from 'pages/onBoarding/firstPage'
import OnBoardingSecondPage from 'pages/onBoarding/secondPage'
import OnBoardingThirdPage from 'pages/onBoarding/thirdPage'
import OnBoardingFourthPage from 'pages/onBoarding/fourthPage'
import OnBoardingFifthPage from 'pages/onBoarding/fifthPage'
import OnBoardingSixthPage from 'pages/onBoarding/sixthPage'
import OnBoardingSeventhPage from 'pages/onBoarding/seventhPage'

// Event 
import DetailPage from 'pages/Event/DetailPage';
import EventFilterPage from 'pages/Event/EventFilterPage';
import Discover from 'pages/Discover';
import Booking from "pages/Event/booking"

// Settings Start
import Settings from 'pages/settings/settings'
import ProfilePreview from 'pages/settings/profilePreview'

// Profile Edit start
import BasicInformationProfileEditPage from 'pages/ProfileEdit/BasicInformation'
import PhysicalAttributesEditPage from 'pages/ProfileEdit/PhysicalAttributes'
import DietAttributesEditPage from 'pages/ProfileEdit/DietAttributes'
import GroomsLocationEditPage from 'pages/ProfileEdit/groomsLocation'
import FamilyDetailsEditPage from 'pages/ProfileEdit/FamilyDetails'
import PhotoAndGalleryEditPage from 'pages/ProfileEdit/PhotoAndGallery'
import LanguagesEditPage from 'pages/ProfileEdit/Languages'
import InterestEditPage from 'pages/ProfileEdit/Interest'
import EducationDetailsEditPage from 'pages/ProfileEdit/EducationDetails'
import ConnectedAccountsEditPage from 'pages/ProfileEdit/ConnectedAccounts'
// Profile Edit End

import PartnerPreference from 'pages/settings/partnerPreference'
import WalletSettings from 'pages/settings/wallet'
import PrivacySettings from 'pages/settings/privacySettings'
import AccountSettings from 'pages/settings/accountSettings'
import Logout from 'pages/settings/logout'
// Settings End

import HomPage from "pages/Home"

// Connection start
import FriendsConnectionPage from "pages/connections/FriendsPage"
import SuggestedConnectionPage from "pages/connections/SuggestionPage"
import EventsConnectionPage from "pages/connections/EventsPage"
import RequestedConnectionPage from "pages/connections/RequestedPage"
import FriendsProfilePreviewPage from "pages/connections/friendsProfilePreview"
import RequestedProfilePrev from "pages/connections/requestedProfilePrev"
import QrConnectionProfilePrev from "pages/connections/qrConnectionProfilePrev"
// Connection End

// Wallet Section
import WalletPageNew from "pages/WalletSection/WalletPage"
import WalletRechargePage from "pages/WalletSection/WalletRechargePage"
import PlanesPage from "pages/WalletSection/PlanesPage"
import WalletCard from "pages/WalletSection/WalletCard"
import WalletHistoryPage from "pages/WalletSection/history"
import Scan from "pages/WalletSection/scan"

// Offers
import OffersPage from "pages/offers/offers"
import OffersDetailsPage from "pages/offers/details"

// Chat
import ChatPage from "pages/chat/ChatPage"
// Meetings
import MeetingPage from "pages/meetings/MeetingPage"

// GPS
import GPSPage from "pages/gps/gps"


// Cupid
import Cupid from "pages/cupid"

// Protect Component
import ProtectComponent from "components/ProtectComponent"
import UnAuthRoute from "components/ProtectComponent/unAuth";

// Error
import ServerError from "components/Cards/ErrorCards/serverError";
import AllCard from 'components/AllCard'

// license
import TermsAndConditions from "pages/license/TermsAndConditions";
import PrivacyPolicy from "pages/license/PrivacyPolicy";


import { AUTH, BLOG, BOOKING_PAYMENT, CONNECTIONS, CUPID, ERROR, EVENTS, Error_CARDS, GPS, LICENSE, MAIN_NAV, MEETINGS, OFFERS, ON_BOARDING_PAGES, PROFILE_EDIT, SETTINGS, SITE_INTO, WALLET } from "../shared/router";

export const Links = [

    // ========== NEW VERSION START  ========== //
    //  SITE INTRO START
    {
        name: "Landing Page",
        path: SITE_INTO.LANDING,
        element: <Landing />,
        showInNavigation: true,
    },
    {
        name: "About Us Page",
        path: SITE_INTO.ABOUT,
        element: <AboutUs />,
        showInNavigation: true,
    },
    {
        name: "Event Home Page",
        path: SITE_INTO.EVENT_HOME,
        element: <EventHome />,
        showInNavigation: true,
    },
    {
        name: "Host with us Page",
        path: SITE_INTO.HOST_WITH_US,
        element: <HostWithUs />,
        showInNavigation: true,
    },
    {
        name: "Datrmony Page",
        path: SITE_INTO.DATRIMONY,
        element: <Datrimony />,
        showInNavigation: true,
    },
    {
        name: "Blog Page",
        path: SITE_INTO.BLOG,
        element: <Blog />,
        showInNavigation: true,
    },
    {
        name: "Blog Detail Page",
        path: BLOG.BLOG_DETAILS,
        element: <BlogDetail />,
        showInNavigation: true,
    },
    //  SITE INTRO END

    // AUTH START
    {
        name: "Signup",
        path: AUTH.SIGNUP,
        element: <UnAuthRoute children={<SignUpPage />} />,
        showInNavigation: true,
    },
    {
        name: "Login",
        path: AUTH.LOGIN,
        element: <UnAuthRoute children={<LoginPage />} />,
        showInNavigation: true,
    },
    {
        name: "Welcome Page",
        path: AUTH.WELCOME,
        element: <UnAuthRoute children={<WelcomePage />} />,
        showInNavigation: true,
    },
    // AUTH END

    // ON BOARDING STARTS
    {
        name: "On Boarding Page One",
        path: ON_BOARDING_PAGES.FIRST,
        element: <OnBoardingFirstPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Two",
        path: ON_BOARDING_PAGES.SECOND,
        element: <OnBoardingSecondPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Three",
        path: ON_BOARDING_PAGES.THIRD,
        element: <OnBoardingThirdPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Fourth",
        path: ON_BOARDING_PAGES.FOURTH,
        element: <OnBoardingFourthPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Fifth",
        path: ON_BOARDING_PAGES.FIFTH,
        element: <OnBoardingFifthPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Sixth",
        path: ON_BOARDING_PAGES.SIXTH,
        element: <OnBoardingSixthPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Seventh",
        path: ON_BOARDING_PAGES.SEVENTH,
        element: <OnBoardingSeventhPage />,
        showInNavigation: true,
    },
    // ON BOARDING END

    // CONNECTIONS STARTS
    {
        name: "Discover",
        path: MAIN_NAV.DISCOVER,
        element: <ProtectComponent children={<Discover />} />,
        showInNavigation: true,
    },
    {
        name: "Cupid Page",
        path: CUPID,
        element: <Cupid />,
        showInNavigation: true,
    },
    {
        name: "Connection Page Friends",
        path: CONNECTIONS.FRIENDS,
        element: <ProtectComponent children={<FriendsConnectionPage />} />,
        showInNavigation: true,
    },
    {
        name: "Suggestion Page",
        path: CONNECTIONS.SUGGESTIONS,
        element: <ProtectComponent children={<SuggestedConnectionPage />} />,
        showInNavigation: true,
    },
    {
        name: "Events Connection Page",
        path: CONNECTIONS.EVENTS,
        element: <ProtectComponent children={<EventsConnectionPage />} />,
        showInNavigation: true,
    },
    {
        name: "Request Page",
        path: CONNECTIONS.REQUESTS,
        element: <ProtectComponent children={<RequestedConnectionPage />} />,
        showInNavigation: true,
    },
    {
        name: "Friends Prev Page",
        path: CONNECTIONS.FRIEND_PREVIEW,
        element: <ProtectComponent children={<FriendsProfilePreviewPage />} />,
        showInNavigation: true,
    },
    {
        name: "User Prev Page for connection request send and received user",
        path: CONNECTIONS.REQUESTED_CONNECTION_PROFILE_PREVIEW,
        element: <ProtectComponent children={<RequestedProfilePrev />} />,
        showInNavigation: true,
    },
    {
        name: "User Prev Page for qr Connection",
        path: CONNECTIONS.CONNECTION_FRIEND_PREVIEW,
        element: <ProtectComponent children={<QrConnectionProfilePrev />} />,
        showInNavigation: true,
    },
    // CONNECTIONS END

    // Home Page
    {
        name: "HomPage",
        path: MAIN_NAV.HOME,
        element: <ProtectComponent children={<HomPage />} />,
        showInNavigation: true,
    },
    // EVENT PAGES START
    {
        name: "Event Detail Page authorized",
        path: EVENTS.EVENT_DETAILS,
        element: <DetailPage />,
        showInNavigation: true,
    },
    {
        name: "Event Booking page",
        path: BOOKING_PAYMENT,
        element: <Booking />,
        showInNavigation: true,
    },
    {
        name: "Event Filter Page",
        path: EVENTS.EVENT_FILTER,
        element: <EventFilterPage />,
        showInNavigation: true,
    },
    //    EVENT PAGES END

    // Settings Start
    {
        name: "Settings",
        path: SETTINGS.SETTINGS,
        element: <ProtectComponent children={<Settings />} />,
        showInNavigation: true,
    },
    {
        name: "Settings",
        path: SETTINGS.PROFILE_PREVIEW,
        element: <ProtectComponent children={<ProfilePreview />} />,
        showInNavigation: true,
    },
    // Profile Edit start
    {
        name: "Profile Edit Basic Information",
        path: PROFILE_EDIT.BASIC,
        element: <ProtectComponent children={<BasicInformationProfileEditPage />} />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Physical Attributes",
        path: PROFILE_EDIT.PHYSICAL,
        element: <ProtectComponent children={<PhysicalAttributesEditPage />} />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Dies Attributes",
        path: PROFILE_EDIT.DIET,
        element: <ProtectComponent children={<DietAttributesEditPage />} />,
        showInNavigation: true,
    },
    {
        name: "Profile edit Location Details",
        path: PROFILE_EDIT.LOCATION,
        element: <ProtectComponent children={<GroomsLocationEditPage />} />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Family Details",
        path: PROFILE_EDIT.FAMILY_DETAILS,
        element: <ProtectComponent children={<FamilyDetailsEditPage />} />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Photos",
        path: PROFILE_EDIT.PHOTO,
        element: <ProtectComponent children={<PhotoAndGalleryEditPage />} />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Languages",
        path: PROFILE_EDIT.LANGUAGES,
        element: <ProtectComponent children={<LanguagesEditPage />} />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Interest",
        path: PROFILE_EDIT.INTERESTS,
        element: <ProtectComponent children={<InterestEditPage />} />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Education Details",
        path: PROFILE_EDIT.EDUCATION,
        element: <ProtectComponent children={<EducationDetailsEditPage />} />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Connected Accounts",
        path: PROFILE_EDIT.CONNECTED_ACCOUNTS,
        element: <ProtectComponent children={<ConnectedAccountsEditPage />} />,
        showInNavigation: true,
    },
    // Profile Edit End

    {
        name: "Partner Preference",
        path: SETTINGS.PARTNER_PREFERENCE,
        element: <ProtectComponent children={<PartnerPreference />} />,
        showInNavigation: true,
    },
    {
        name: "Privacy Settings",
        path: SETTINGS.PRIVACY_SETTINGS,
        element: <ProtectComponent children={<PrivacySettings />} />,
        showInNavigation: true,
    },
    {
        name: "Privacy Settings",
        path: SETTINGS.ACCOUNT_SETTINGS,
        element: <ProtectComponent children={<AccountSettings />} />,
        showInNavigation: true,
    },
    {
        name: "Wallet Settings",
        path: SETTINGS.WALLET,
        element: <ProtectComponent children={<WalletSettings />} />,
        showInNavigation: true,
    },
    {
        name: "lOGOUT Page",
        path: SETTINGS.LOGOUT,
        element: <ProtectComponent children={<Logout />} />,
        showInNavigation: true,
    },
    // Settings End

    // Wallet
    {
        name: "Wallet Page",
        path: WALLET.WALLET,
        element: <ProtectComponent children={<WalletPageNew />} />,
        showInNavigation: true,
    },
    {
        name: "Wallet Card Page",
        path: WALLET.CARD,
        element: <ProtectComponent children={<WalletCard />} />,
        showInNavigation: true,
    },

    {
        name: "Wallet Recharge Page",
        path: WALLET.RECHARGE,
        element: <ProtectComponent children={<WalletRechargePage />} />,
        showInNavigation: true,
    },
    {
        name: "planes Page",
        path: WALLET.PLANES,
        element: <ProtectComponent children={<PlanesPage />} />,
        showInNavigation: true,
    },
    {
        name: "wallet history Page",
        path: WALLET.HISTORY,
        element: <ProtectComponent children={<WalletHistoryPage />} />,
        showInNavigation: true,
    },
    {
        name: "wallet history Page",
        path: WALLET.SCAN,
        element: <ProtectComponent children={<Scan />} />,
        showInNavigation: true,
    },


    // Offers
    {
        name: "OFFERS Page",
        path: OFFERS.OFFERS,
        element: <ProtectComponent children={<OffersPage />} />,
        showInNavigation: true,
    },
    {
        name: "OFFER DETAILS Page",
        path: OFFERS.OFFER_DETAILS,
        element: <ProtectComponent children={<OffersDetailsPage />} />,
        showInNavigation: true,
    },

    // Chat
    {
        name: "Chat Page",
        path: MAIN_NAV.CHAT,
        element: <ProtectComponent children={<ChatPage />} />,
        showInNavigation: true,
    },
    // Meetings
    {
        name: "Meetings Page",
        path: MEETINGS,
        element: <ProtectComponent children={<MeetingPage />} />,
        showInNavigation: true,
    },

    // LICENSE START
    {
        name: "Terms And Conditions Page",
        path: LICENSE.TERMS_AND_CONDITIONS,
        element: <TermsAndConditions />,
        showInNavigation: true,
    },
    {
        name: "Privacy Policy Page",
        path: LICENSE.PRIVACY_POLICIES,
        element: <PrivacyPolicy />,
        showInNavigation: true,
    },
    // GPS
    {
        name: "GPS Page",
        path: GPS.GPS,
        element: <GPSPage />,
        showInNavigation: true,
    },
    // LICENSE END
    // ERROR PAGE START
    {
        name: "Server Error Page",
        path: ERROR.SERVER_ERROR,
        element: <ServerError />,
        showInNavigation: true,
    },
    {
        name: "Error Cards",
        path: Error_CARDS,
        element: <AllCard />,
        showInNavigation: true,
    },
    // ERROR PAGES END

    // ========== NEW VERSION END ==========
];

import React from 'react'

function ActivityCard({ title, value }) {
    return (
        <div className="bg-white shadow border rounded-lg p-4">
            <h3 className="text-xl text-subText">{title}</h3>
            <p className="text-2xl font-semibold mt-2">{value}</p>
        </div>
    )
}

export default ActivityCard
import React, { lazy, Suspense, useState } from 'react'
import couple from 'assets/login/couple.png'
import india from 'assets/login/India (IN).svg'
import google from 'assets/login/flat-color-icons_google.svg'
import mobile from 'assets/login/login1.png'
import mobile2 from 'assets/login/login2.png'
import mobileThird from 'assets/login/login3.png'

import { toast } from "react-toastify"
import * as Yup from 'yup';
import { emailValidation, otpValidation, phoneValidation } from 'utils/validations/login'
import { loginWithEmail, loginWithPhone, sendOtpToEmail, sendOtpToPhone } from 'apis/login'
import { Link, useNavigate } from 'react-router-dom'
import { AUTH, MAIN_NAV } from 'shared/router'
import ContentCard from "components/Cards/loginAndSignInContent"
import { Helmet } from 'react-helmet'

const UserAccountBlocked = lazy(() => import('components/popUps/accountBlocked'))
const Otp = lazy(() => import('./otp'))

function Index() {

    const navigate = useNavigate()

    const [inputType, setInputType] = useState('number');
    const [isVerifyBtn, setIsVerifyBtn] = useState(true)
    const [blockPopup, setBlockPopup] = useState(false)

    const slideImage = [
        {
            src: mobile,
            mainText: 'More Categories,More Events',
            text: "Couple Squad is excited to share that we've expanded our event options! We now offer 8 different categories of events and over 20+ specific events for both Singles and Couples."
        },
        {
            src: mobile2,
            mainText: 'Interest-Specific Events For Everyone',
            text: "Whether you are a single or a couple we have all kinds of events for everyone, from outdoor adventures to professional networking, fitness and wellness activities, community service events, and much more."
        },
        {
            src: mobileThird,
            mainText: 'Pre-Engagement with the Event Attendees',
            text: "Couple Squad offers a unique feature that allows you to view the list of event attendees before joining the event. Get ready to have a great time at the event!"
        }
    ]


    const [data, setData] = useState({
        email: '',
        phone: '',
        otp: ''
    })
    const [otp, setOtp] = useState({
        phoneOtp: false,
        emailOtp: false,
    })

    const handleInputChange = (type) => {
        setInputType(type);
        setIsVerifyBtn(true)
        setOtp({
            phoneOtp: false,
            emailOtp: false,
        });
        setData({
            email: '',
            phone: '',
            otp: ''
        })

    };

    const handleSendOtpToPhone = async () => {
        try {
            await phoneValidation.validate(data, { abortEarly: false });
            setIsVerifyBtn(false)
            const toastId = toast.loading("sending OTP....")
            const res = await sendOtpToPhone({ phone: data.phone })
            if (res?.success) {
                setOtp(prev => ({ ...prev, phoneOtp: true }));
            } else {
                setIsVerifyBtn(true)
            }
            toast.dismiss(toastId)

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const handleSendOtpToEmail = async () => {
        try {
            await emailValidation.validate(data, { abortEarly: false });
            setIsVerifyBtn(false)
            const toastId = toast.loading("OTP Sent")
            const res = await sendOtpToEmail({ email: data.email })
            if (res?.success) {
                setOtp(prev => ({ ...prev, emailOtp: true }));
            } else {
                setIsVerifyBtn(true)
            }
            toast.dismiss(toastId)
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const handleSubmit = async (value) => {
        const toastId = toast.loading("verifying Data please wait...")
        try {
            const apiData = {
                email: data?.email || '',
                phone: data?.phone || '',
                otp: value
            }
            let res

            if (otp.phoneOtp) {
                await phoneValidation.validate(apiData, { abortEarly: false });
                await otpValidation.validate(apiData, { abortEarly: false });

                res = await loginWithPhone({ phone: apiData.phone, otp: apiData.otp })
            } else if (otp.emailOtp) {
                await emailValidation.validate(apiData, { abortEarly: false });
                await otpValidation.validate(apiData, { abortEarly: false });

                res = await loginWithEmail({ email: apiData.email, otp: apiData.otp })
            } else {
                toast.error(`Please verify your ${inputType === 'number' ? "phone No" : "Email"}`)
            }
            toast.dismiss(toastId)

            const redirectUrl = localStorage.getItem('redirectUrl')

            if (res?.response?.data?.type === 'ACCOUNT_DEACTIVATED') {
                setBlockPopup(true)
            }

            if (res?.success) {

                if (redirectUrl) {
                    window.location.assign(redirectUrl);
                    localStorage.removeItem('redirectUrl');
                }
                else if (res.user.onboarding_completed) {
                    // eslint-disable-next-line no-restricted-globals
                    navigate(MAIN_NAV.HOME, { state: { from: location.pathname } });
                } else {
                    navigate('/on-boarding-1')
                }

                localStorage.setItem("session", res.data)
            }
        } catch (error) {
            toast.dismiss(toastId)
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }


    return (
        <div>
            {(otp.emailOtp || otp.phoneOtp) ? (
                <Suspense>
                    <Otp
                        value={otp.emailOtp ? data?.email : data?.phone}
                        resend={() => otp.emailOtp ? handleSendOtpToEmail() : handleSendOtpToPhone()}
                        handleSubmit={(value) => handleSubmit(value)}
                    />
                </Suspense>
            ) :

                <div className='flex flex-col w-full h-full bg-white lg:flex-row'>

                    <Helmet>
                        <meta name='title' content="Login to Couple Squad | Access Your Profile & Event Dashboard" />
                        <meta name='description' content='Log in to Couple Squad to view your profile and event dashboard. Manage your details and stay updated on events easily.' />
                    </Helmet>

                    {blockPopup &&
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                            <div className="relative">
                                <Suspense children={<UserAccountBlocked closeBtn={() => setBlockPopup(false)} />} fallback={<div>loading...</div>} />
                            </div>
                        </div>
                    }

                    <div className='flex flex-col hidden w-full lg:block max-h-1/2 max-lg:h-screen lg:w-1/2 bg-brandRed lg:items-start md:items-center'>
                        <ContentCard slides={slideImage} />
                    </div>
                    <div className="flex flex-col items-center justify-center w-full h-screen text-black bg-white lg:w-1/2">

                        <div className='hidden md:mt-10 lg:mt-0 md:block'>
                            <img src={couple} alt='couplesquad' />
                        </div>
                        <div className='w-full md:max-w-[430px] mb-7 flex flex-col justify-between items-center px-4'>
                            <p className='mb-1 text-2xl font-semibold text-black sm:text-3xl md:text-4xl'>Welcome Back!</p>
                            <p className='text-sm font-normal text-gray-700 sm:text-base'>Log in to your Couple Squad account.</p>
                        </div>

                        <div className='w-full md:w-[430px] flex flex-col gap-5 lg:gap-10 bg-white'>
                            <div className='flex items-center justify-evenly '>
                                <div className='relative flex flex-col items-center justify-center gap-2 text-base cursor-pointer'>
                                    <button onClick={() => handleInputChange('number')} className={inputType === 'number' ? 'text-black font-semibold' : 'text-gray-700'}>Phone</button>
                                    {inputType === 'number' &&
                                        <div className='absolute bottom-0 left-1/2 transform -translate-x-1/2 w-20 h-0.5 bg-brandRed'></div>
                                    }
                                </div>

                                <div className='relative text-base font-normal cursor-pointer'>
                                    <button onClick={() => handleInputChange('email')} className={inputType === 'email' ? 'text-black font-semibold' : 'text-gray-700'}>
                                        Email
                                        {inputType === 'email' &&
                                            <div className='absolute bottom-0 left-1/2 transform -translate-x-1/2 w-20 h-0.5 bg-brandRed'></div>
                                        }
                                    </button>
                                </div>
                            </div>

                            <div className="flex flex-col items-center w-full px-4 text-black bg-white sm:px-6 md:px-8 lg:px-10">
                                {inputType === 'number' ?
                                    <div className="relative flex items-center justify-center w-[75%] md:w-full mb-4 border">
                                        <span className="absolute left-0 flex items-center">
                                            <img src={india} alt="IN" className="w-8 h-5 mr-2" />
                                            +91
                                        </span>
                                        <input type="text" maxLength="10" placeholder="Enter Mobile Number" className="w-full px-4 py-2 bg-white border-none ms-16 focus:outline-none"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*$/.test(value)) {
                                                    setData(prev => ({ ...prev, phone: value }))
                                                }
                                            }} />
                                        {data.phone.length === 10 && isVerifyBtn && (
                                            <button
                                                onClick={() => handleSendOtpToPhone()}
                                                className="absolute px-3 py-1 text-xs text-green-500 transform -translate-y-1/2 bg-white border border-green-500 right-2 top-1/2 sm:text-sm"
                                            >
                                                Verify
                                            </button>
                                        )}
                                    </div>
                                    :
                                    <div className="relative flex items-center justify-center w-[75%] md:w-full mb-4">
                                        <input type="email" placeholder="Email" className="w-full px-4 py-2 bg-white border"
                                            onChange={(e) => setData(prev => ({ ...prev, email: e.target.value }))} />
                                        {data.email.includes('@') && isVerifyBtn && (
                                            <button
                                                onClick={() => handleSendOtpToEmail()}
                                                className="absolute px-3 py-1 text-xs text-green-500 transform -translate-y-1/2 bg-white border border-green-500 right-2 top-1/2 sm:text-sm"
                                            >
                                                Verify
                                            </button>
                                        )}
                                    </div>
                                }

                                <p className="mb-4 text-xs text-center sm:text-sm">Don't have an account? <Link to={AUTH.SIGNUP} className="text-blue-500">Sign up</Link></p>

                            </div>

                        </div>


                    </div>
                </div >
            }
        </div>
    )
}

export default Index
import { requestContactInfo, viewContactInfo } from 'apis/contact';
import React, { useState, useMemo } from 'react';
import { capitalize } from 'shared/textFormats';

// Assets
import men from 'assets/discover/men.svg';
import female from 'assets/discover/GenderFemale.svg';
import cm from 'assets/discover/cm.svg';
import kg from 'assets/discover/kg.svg';
import jobIcon from 'assets/discover/lawyer2.svg';
import phone from 'assets/discover/icons/phone.png';
import religion from 'assets/discover/icons/religion-black.svg';
import location from 'assets/discover/mynaui_location.svg';


function Index({ data }) {

    const basicInfo = useMemo(() => [
        { label: capitalize(data?.personal?.marital_status), icon: null },
        { label: capitalize(data?.personal?.sex), icon: data?.personal?.sex === 'male' ? men : female },
        { label: data?.personal?.height ? `${data?.personal?.height}cm` : null, icon: cm },
        { label: data?.personal?.weight ? `${data?.personal?.weight}kg` : null, icon: kg },
    ], [data]);

    if (data?.personal?.relationship_status === 'single') basicInfo.push({ label: capitalize(data?.personal?.religion_id?.name), icon: religion })

    const interestColors = ['blue', 'yellow', 'orange', 'green'];


    const [contactInfo, setContactInfo] = useState({
        enabled: data?.preferred_contacts?.personal_contact?.enabled,
        status: data?.preferred_contacts?.personal_contact?.status,
        requestId: data?.preferred_contacts?.personal_contact?.requestId,
        request_type: data?.preferred_contacts?.personal_contact?.request_type,
        contactNumber: false,
        relation: false,
    });

    const handleRequestContactInfo = async (type) => {
        const res = await requestContactInfo(data?.id, type);
        if (res?.success) {
            setContactInfo((prevContactInfo) => ({
                ...prevContactInfo,
                status: res.data?.status,
            }));
        }
    };

    const handleViewContactInfo = async (id) => {
        const res = await viewContactInfo(id);
        if (res?.success) {
            setContactInfo((prevContactInfo) => ({
                ...prevContactInfo,
                contactNumber: prevContactInfo.requestId === res?.data?.request_id ? res?.data?.contact : prevContactInfo.contactNumber,
            }));
        }
    };

    const classState = useMemo(() => [
        { title: 'request', style: 'bg-gray-200 border text-subText' },
        { title: 'accepted', style: 'text-white bg-blue-500' },
        { title: 'requested', style: 'text-white bg-brandRed' },
        { title: 'rejected', style: 'text-white bg-black' },
        { title: 'expired', style: 'text-white bg-subText' },
    ], []);

    const getStyleByStatus = (status) => {
        const state = classState.find((state) => state.title === status);
        return state ? state.style : '';
    };

    const handleOnClickContactInfo = (status) => {
        if (status === 'request') {
            handleRequestContactInfo(contactInfo?.request_type);
        } else if (status === 'accepted' && !contactInfo?.contactNumber) {
            handleViewContactInfo(contactInfo?.requestId);
        }
    };

    return (
        <div className="w-full h-full">
            <p className="font-medium text-lg md:text-[20px] text-text_gray">My basics</p>
            <div className="flex flex-wrap gap-3 mt-2">
                {basicInfo?.map((info, index) => info?.label && (
                    <div key={index} className="w-fit h-[46px] flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3">
                        {info?.icon && <img src={info?.icon} alt="icon" />}
                        <p className="font-normal text-black text-sm md:text-[16px]">{info?.label}</p>
                    </div>
                ))}
            </div>

            {data?.personal?.profession && (
                <div className="w-fit h-[89px] flex flex-col gap-3 mt-6">
                    <p className="font-medium text-lg md:text-[20px] text-text_gray">Profession</p>
                    <div className="w-fit h-[46px] flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3 px-6">
                        <img src={jobIcon} alt="job" />
                        <p className="font-normal text-black text-[16px]">{capitalize(data?.personal?.profession)}</p>
                    </div>
                </div>
            )}

            {contactInfo?.enabled && (
                <div className="flex items-center mb-4">
                    <div className="w-full">
                        <h2 className="font-medium text-lg md:text-[20px] text-text_gray mb-2">Contact Mode</h2>
                        <div className="flex items-center justify-between w-full">
                            <button className="flex items-center gap-2 px-4 py-2 text-gray-600 border border-gray-300 rounded-full">
                                <img src={phone} alt="phone" />
                                Personal
                            </button>
                            <button
                                className={`flex ms-20 items-center px-6 py-2 font-semibold transition rounded-lg ${getStyleByStatus(contactInfo?.status)}`}
                                onClick={() => handleOnClickContactInfo(contactInfo?.status)}
                            >
                                {contactInfo?.status === 'accepted' && !contactInfo?.contactNumber
                                    ? 'View'
                                    : contactInfo?.status === 'accepted'
                                        ? contactInfo?.contactNumber
                                        : capitalize(contactInfo?.status)}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {data?.languages_known?.others?.length > 0 && (
                <div className="w-fit h-[89px] flex flex-wrap flex-col gap-3 mt-6">
                    <p className="font-medium text-lg md:text-[20px] text-text_gray">Languages known</p>
                    <div className="flex gap-2">
                        {data?.languages_known?.others?.map((language, index) => (
                            <div key={index} className="w-fit h-[46px] flex justify-center items-center border-2 border-border_color rounded-full p-3">
                                <p className="font-normal text-black text-[16px]">{capitalize(language)}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {data?.personal?.looking_for && (
                <div className="w-fit h-[89px] flex flex-col gap-3 mt-6">
                    <p className="font-medium text-lg md:text-[20px] text-text_gray">Looking for</p>
                    <div className="w-fit h-[46px] flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3">
                        <p className="font-normal text-black text-[16px]">{capitalize(data?.personal?.looking_for)}</p>
                    </div>
                </div>
            )}

            {data?.personal?.ctc && (
                <div className="w-fit h-[89px] flex flex-col gap-3 mt-6">
                    <p className="font-medium text-lg md:text-[20px] text-text_gray">CTC</p>
                    <div className="w-fit h-[46px] flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3">
                        <p className="font-normal text-black text-[16px]">{`${data?.personal?.ctc} LPA`}</p>
                    </div>
                </div>
            )}

            {data?.misc?.interests?.length > 0 && (
                <div className="flex flex-col gap-2">
                    <p className="font-medium text-lg md:text-[20px] text-text_gray">Interested in</p>
                    <div className="flex flex-wrap gap-3">
                        {data?.misc?.interests.map((item, index) => (
                            <div
                                key={item}
                                className={`w-fit h-[44px] bg-${interestColors[index]}-700 bg-opacity-20 flex justify-center items-center rounded-full gap-1 p-3`}
                            >
                                <p className={`font-normal text-${interestColors[index]}-700 text-[16px]`}>#{item}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {data?.address && (
                <div className="w-fit md:w-[147px] h-[60px] flex flex-col gap-2 my-8">
                    <p className="font-medium text-lg md:text-[20px] text-text_gray">Location</p>
                    <div className="flex gap-2">
                        <img src={location} alt="location" />
                        <p className="font-normal text-black text-[16px]">
                            {data?.address?.state && `${capitalize(data?.address?.state)}, `}
                            {capitalize(data?.address?.country)}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Index;

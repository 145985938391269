import React, { lazy, Suspense, useEffect, useState } from 'react'
import banner from 'assets/offers/images/banner.png'
import LazyImage from 'components/image/lazyLoading'
import { getOfferById } from 'apis/offers'

import NavBar from 'components/Navbars/mainHeader'
import NavBarResponsive from 'components/Navbars/mainHeader/responsive'
import ResponsiveBottomNav from 'components/Navbars/responsiveBottomNav'
import { useParams } from 'react-router-dom'
import { decodeFromBase64 } from 'utils/encodeDecode'
import { formatDate } from 'shared/dates'
import { toast } from 'react-toastify'

const Footer = lazy(() => import('components/Footer'))
const FooterResponsive = lazy(() => import('components/Footer/responsive'))

function Index() {

    const { id } = useParams()
    const [offer, setOffer] = useState()
    const fetchOffer = async () => {
        const res = await getOfferById(decodeFromBase64(id))
        if (res?.success) setOffer(res?.data)
    }

    const handleOnclickCopy = async () => {
        if (offer?.offer_code) {
            if (!navigator.clipboard) {
                toast.error("Clipboard API not supported in this browser.");
            } else {
                toast.success('Code copied to clipboard')
                await navigator.clipboard.writeText(offer?.offer_code);
            }
        }
    }

    useEffect(() => {
        fetchOffer()
    }, [])

    return (
        <div className='min-h-screen bg-white'>

            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>
            <ResponsiveBottomNav />

            <div className="flex flex-col w-[90%] mx-auto my-10 text-black bg-white ">

                <div className="text-start">
                    <div className='text-2xl font-bold'>{offer?.title}</div>
                    <div className='text-subText'>Valid till {formatDate(offer?.end_date)}</div>
                </div>

                <div className="flex justify-between w-full">

                    {/* Left Section */}
                    {/* Offer Image */}
                    <LazyImage src={offer?.image} className="w-[800px] h-[400px]" />

                    {/* Right Section */}
                    <div className="flex flex-col w-full p-6 bg-white border border-gray-300 rounded-lg shadow-md lg:w-1/3">
                        <div className="flex items-center justify-between mb-2">
                            <h2 className="text-lg font-bold">{offer?.title}</h2>
                            <h2 className="text-base text-brandRed">{offer?.offer_code}</h2>
                        </div>

                        <div className="flex-grow space-y-2" dangerouslySetInnerHTML={{ __html: offer?.description }}></div>

                        <div className="flex items-center justify-center mt-auto">
                            <button onClick={handleOnclickCopy} className="w-full p-3 text-white rounded-lg shadow-md bg-brandRed">
                                Copy
                            </button>
                        </div>
                    </div>

                </div>
            </div>


            <div className="mt-10">
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="hidden w-full bg-black lg:block">
                        <Footer />
                    </div>
                    <div className="block mb-8 bg-black lg:hidden">
                        <FooterResponsive />
                    </div>
                </Suspense>
            </div>
        </div>
    )
}

export default Index
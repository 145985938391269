import React, { memo } from 'react';

const SelectableDropdown = ({ title, icons, values, handleChangeFunction, inputKey, selectedValue }) => {
    const currentIcon = icons[selectedValue] || icons[Object.keys(icons)[0]];

    return (
        <>
            <div className='flex mt-7'>
                <img src={currentIcon} alt="icon" className="mb-3 me-1" />
                <span className='text-gray-600'>{title}</span>
            </div>

            <div className="relative">
                <select
                    className="block w-full p-3 pr-10 font-medium bg-white border border-gray-300 rounded-sm appearance-none"
                    name="select-dropdown"
                    value={selectedValue}
                    onChange={(e) => handleChangeFunction(inputKey, e.target.value)}
                >
                    <option>Choose...</option>
                    {values?.map((value) => (
                        <option key={value?.value} value={value?.value}>
                            {value?.label}
                        </option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 9L12 15L6 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
        </>
    );
};

export default memo(SelectableDropdown)

import axios from "axios";
import { toast } from "react-toastify";
import { ERROR } from "shared/router";

export const noAuth = async ({ method, url, data = {}, options = {} }) => {
    try {
        const headers = options?.headers || {};
        delete options["headers"];
        const res = await axios({
            method,
            url: `${process.env.REACT_APP_API_URL}${url}`,
            data,
            headers: {
                "Content-Type": headers.content_type ?? "application/json",
                ...headers,
            },
            ...options,
        });

        if (!res?.data?.success) {
            throw new Error(res?.data?.message);
        }
        return res.data;
    } catch (err) {
        if (err?.response?.data?.errno === 500) {
            window.location.href = ERROR.SERVER_ERROR;
        }
        toast.error(err?.response?.data?.message || err?.message || "Something went wrong!");
        return err
    }
};

export const auth = async ({ method, url, data = {}, options = {} }) => {
    try {
        let token = localStorage?.getItem("session");
        if (!token) {
            throw new Error('Please login to continue');
        }
        const headers = options?.headers || {};
        delete options["headers"];
        const res = await axios({
            method,
            url: `${process.env.REACT_APP_API_URL}${url}`,
            data,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token || ""}`,
                ...headers,
            },
            ...options,
        });

        if (res.headers['x-new-access-token']) {
            localStorage.setItem('session', res.headers['x-new-access-token']);
            token = res.headers['x-new-access-token'];
        }

        if (!res?.data?.success) {
            throw new Error(res?.data?.message);
        }

        return res.data;
    } catch (err) {
        if (err?.response?.data?.errno === 403 && err?.response?.data?.type === 'ACCOUNT_DEACTIVATED') {
            localStorage.removeItem('session');

        }
        if (err?.response?.data?.errno === 403 && err?.response?.data?.type === "TOKENEXPIRED") {
            localStorage.removeItem('session');
        }

        if (err?.response?.data?.errno === 500) {
            window.location.href = ERROR.SERVER_ERROR;
        }
        else {
            toast.error(err?.response?.data?.message || err?.message || "Something went wrong!");
        }
        return err

    }
};

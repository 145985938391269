import React, { useEffect, useState } from 'react';
import ActivityCard from './activityCard';
import FeatureCard from './featureCard ';
import { GPS, SETTINGS, WALLET } from 'shared/router';

// Assets
import statusIcon from 'assets/home/icons/status.png'
import nearestUserIcon from 'assets/home/icons/nearestUser.png'
import scannerIcon from 'assets/home/icons/scanner.png'
import qrCodeIcon from 'assets/home/icons/qrCode.png'
import walletIcon from 'assets/home/icons/wallet.png'
import walletSecondaryIcon from 'assets/home/icons/walletSecondary.png'
import { getUserDetailsForHome } from 'apis/home';

function Index() {
    const [userInfo, setUserInfo] = useState()

    const featuresData = [
        {
            icon: statusIcon, title: 'Status', link: SETTINGS.SETTINGS,
            secondaryIcon: statusIcon, secondaryTitle: userInfo?.verification?.status, secondaryLink: SETTINGS.SETTINGS, textColor: 'text-blue-500', bgColor: 'bg-blue-100'
        },
        {
            icon: nearestUserIcon, title: 'Find Nearest User', link: '#',
            secondaryIcon: nearestUserIcon, secondaryTitle: 'GPS', secondaryLink: '#', textColor: '', bgColor: 'bg-[#dce2f0]'
        },
        {
            icon: walletIcon, title: 'My Wallet', link: WALLET.WALLET,
            secondaryIcon: walletSecondaryIcon, secondaryTitle: `${userInfo?.wallet?.last_transaction_type} ${userInfo?.wallet?.last_transaction}`, secondaryLink: WALLET.WALLET, textColor: 'text-green-500', bgColor: 'bg-green-100'
        },
        {
            icon: scannerIcon, title: 'Scan QR', link: WALLET.SCAN,
            secondaryIcon: qrCodeIcon, secondaryTitle: 'Show QR', secondaryLink: WALLET.CARD, textColor: 'text-purple-500', bgColor: 'bg-purple-100'
        },
    ]


    const fetchUserDetails = async () => {
        const res = await getUserDetailsForHome()
        if (res?.success) setUserInfo(res?.data)
    }

    useEffect(() => {
        fetchUserDetails()
    }, [])

    return (
        <div className="w-full p-6 mx-auto text-black">
            {/* Header Section */}
            <div className="flex flex-col items-center justify-between p-6 text-white rounded-lg bg-gradient-to-r from-pink-500 to-purple-500 sm:flex-row">
                <div>
                    <h2 className="text-2xl font-semibold">Welcome back, {userInfo?.name}!</h2>
                    <p className="mt-1 text-sm">Always stay updated in Couple Squad Profile</p>
                </div>
                <div className="mt-4 sm:mt-0">
                    <img
                        src={userInfo?.photo}
                        alt="Profile"
                        className="object-cover w-20 h-20 rounded-full"
                    />
                </div>
            </div>

            {/* Activity Section */}
            <div className="text-[26px] font-semibold mt-6 mb-4">Activity</div>
            <div className="grid grid-cols-1 gap-6 text-center sm:grid-cols-3">
                <ActivityCard title={'Balance'} value={userInfo?.wallet?.balance} />
                <ActivityCard title={'Connection'} value={userInfo?.connections} />
                <ActivityCard title={'Plan'} value={userInfo?.wallet?.current_plan} />
            </div>

            {/* Feature Section */}
            <h2 className="mt-6 mb-4 text-2xl font-bold">Features</h2>
            <div className="flex flex-col justify-between space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                {featuresData?.map((feature, index) => (
                    <div className="w-full md:w-[30%] min-h-[130px]" key={index}>
                        <FeatureCard
                            icon={feature?.icon}
                            title={feature?.title}
                            link={feature?.link}
                            secondaryTitle={feature?.secondaryTitle}
                            secondaryIcon={feature?.secondaryIcon}
                            secondaryLink={feature?.secondaryLink}
                            textColor={feature?.textColor}
                            bgColor={feature?.bgColor}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Index;

import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';

import NavBar from 'components/Navbars/mainHeader'
import NavBarResponsive from 'components/Navbars/mainHeader/responsive'
import ResponsiveBottomNav from 'components/Navbars/responsiveBottomNav'
import OptionsGrid from 'components/GPS/optionsGrid'
import { useSelector } from 'react-redux';
import avatar from 'assets/avatar.png'
import { connectSocket, disconnectSocket, socketEmitter } from 'socket/SocketManager';
import { toast } from 'react-toastify';
import { useSocketListenerWithConnectedSocket } from 'hooks/socketListern';

const Footer = lazy(() => import('components/Footer'))
const FooterResponsive = lazy(() => import('components/Footer/responsive'))
const ScanningSection = lazy(() => import('components/GPS/scanningSection'))
const PeoplesList = lazy(() => import('components/GPS/peoplesList'))

function Index() {
    const [currentIndex, setCurrentIndex] = useState(1)
    const [location, setLocation] = useState(null);
    const [users, setUsers] = useState([])
    const userInfo = useSelector((state) => state.userProfile);



    useEffect(() => {
        connectSocket()
        return () => {
            socketEmitter('exit_location')
            disconnectSocket();
        };
    }, [])


    const fetchLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setLocation({ lat: latitude, long: longitude });
            });
        } else {
            toast.error('Geolocation is not supported by this browser.');
        }
    };

    const handleNewUsersStateUpdate = useCallback((newUsersData) => {
        const newUsersDataArray = Array.isArray(newUsersData) ? newUsersData : [newUsersData];
        setUsers((prevUsers) => {
            const updatedUsers = [...prevUsers];
            newUsersDataArray.forEach((newUserData) => {
                const userIndex = updatedUsers.findIndex((user) => user.userId === newUserData.userId);
                if (userIndex !== -1) {
                    updatedUsers[userIndex] = { ...updatedUsers[userIndex], ...newUserData };
                } else {
                    updatedUsers.push(newUserData);
                }
            });
            return updatedUsers;
        });
    }, [setUsers]);

    const handleUserExitStateUpdate = useCallback((userData) => {
        setUsers((prevUsers) => {
            const updatedUsers = prevUsers.filter(user => user.userId !== userData.userId);
            return updatedUsers;
        });
    }, [setUsers]);


    useEffect(() => {
        // Fetch initial location
        fetchLocation();
        // Set up an interval to fetch location every 10 seconds
        const locationInterval = setInterval(() => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const { latitude, longitude } = position.coords;

                    // Check if the location has changed
                    if (!location || location.lat !== latitude || location.long !== longitude) {
                        const data = {
                            latitude: latitude,
                            longitude: longitude,
                            radius: 8
                        }
                        socketEmitter('enter_location', data);
                        setLocation({ lat: latitude, long: longitude });
                    }
                });
            }
        }, 1000); // 10 seconds
        // Clear the interval when the component is unmounted
        return () => clearInterval(locationInterval);
    }, [location]);

    // Socket Lister's
    // Socket Error Lister
    const handleErrorMessage = useCallback((data) => {
        toast.error(data?.message)
    }, [])
    useSocketListenerWithConnectedSocket("error", handleErrorMessage);

    // Socket For new users
    const handleNearByUsers = useCallback((data) => {
        console.log('handleNearByUsers', data)
        handleNewUsersStateUpdate(data)
    }, [])
    useSocketListenerWithConnectedSocket("nearby_users", handleNearByUsers);

    // new nearby user
    const handleNewNearByUsers = useCallback((data) => {
        console.log('handleNewNearByUsers', data)
        handleNewUsersStateUpdate(data)
    }, [])
    useSocketListenerWithConnectedSocket("new_nearby_user", handleNewNearByUsers);

    // nearby user Exit
    const handleNearByUserExit = useCallback((data) => {
        console.log('handleNearByUserExit', data)
        handleUserExitStateUpdate(data)
    }, [])
    useSocketListenerWithConnectedSocket("user_exited", handleNearByUserExit);



    return (
        <div className="min-h-screen bg-white">

            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>

            <ResponsiveBottomNav />

            {/* Wrapper for Centering the Content */}
            <div className="flex flex-col items-center justify-center w-full">

                {/* Header Section */}
                <div className="w-[96%] md:w-[80%] bg-indigo-500 text-white rounded-lg shadow-lg p-6 flex justify-between items-center my-10">
                    <div>
                        <h1 className="text-2xl font-bold">Welcome GPS Nearby Tracking</h1>
                        <p className="mt-2 text-sm">Always stay updated in Couple Squad Profile</p>
                    </div>
                    <img src={userInfo?.photo || avatar} alt='' className="hidden w-16 h-16 bg-gray-300 rounded-full md:block" />
                </div>

                {currentIndex === 1 &&
                    <div className="w-[96%] md:w-[80%] mb-20">
                        <OptionsGrid handleScan={() => setCurrentIndex(2)} />
                    </div>
                }

                {currentIndex === 2 &&
                    <div className="w-[96%] md:w-[80%] mb-[50%] md:mb-0">
                        <Suspense children={<ScanningSection handleComplete={() => setCurrentIndex(3)} users={users} />} fallback={<div>loading....</div>} />
                    </div>
                }
                {currentIndex === 3 &&
                    <div className="w-[96%] md:w-[80%] mb-20">
                        <Suspense children={<PeoplesList users={users} />} fallback={<div>loading....</div>} />
                    </div>
                }

            </div>

            <Suspense fallback={<div>Loading...</div>}>
                <div className="hidden w-full bg-black lg:block">
                    <Footer />
                </div>
                <div className="block mb-8 bg-black lg:hidden">
                    <FooterResponsive />
                </div>
            </Suspense>

        </div>

    );
};

export default Index;

import React, { useState } from 'react'

import {
    // Active
    peopleActive, eventActive, offerActive, hotspotActive,
    // Inactive
    peopleInactive, eventInactive, offerInactive, hotspotInactive,
} from 'assets/gps/icons'

function Index({ handleScan }) {

    const [selectedModel, setSelectedModel] = useState()

    const content = [
        { icons: [peopleActive, peopleInactive], title: 'People', description: "See who's around you! Connect with nearby." },
        { icons: [eventActive, eventInactive], title: 'Events', description: "Discover exciting events happening nearby. Join and meet like-minded people." },
        { icons: [offerActive, offerInactive], title: 'Offers', description: "Check out special offers at locations near." },
        { icons: [hotspotActive, hotspotInactive], title: 'Hotspot', description: "Explore active hotspots in the city where singles are mingling right now." },
    ]


    return (
        <div className="text-black w-full flex flex-col ">
            {/* Title */}
            <div className="font-semibold text-2xl my-5 text-left">Select Mode</div>

            {/* Options Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-10 w-full  self-center">
                {content?.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => setSelectedModel(item?.title)}
                        className={`bg-white cursor-pointer flex items-center w-full p-6 rounded-lg shadow-sm hover:shadow-lg transition-all border ${selectedModel === item?.title ? 'border-indigo-500 text-indigo-500' : 'border-gray-300'}`}
                    >
                        <img
                            src={selectedModel === item?.title ? item?.icons[0] : item?.icons[1]}
                            alt="models icon"
                            className="w-10 h-10 mr-4"
                        />
                        <div className="flex flex-col">
                            <h2 className="text-lg font-semibold mb-2">{item?.title}</h2>
                            <p className={`text-sm ${selectedModel === item?.title ? 'text-indigo-500' : 'text-gray-600'}`}>{item?.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Start Scan Button */}
            <button onClick={handleScan} className="bg-indigo-500 text-white font-semibold py-4 px-8 w-[40%] rounded-md shadow-md hover:bg-indigo-600 transition-all self-center">
                Start Scan
            </button>
        </div>


    )
}

export default Index